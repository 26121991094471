@import '@xpo-ltl/ngx-ltl-core/styles/variables';

.search-form {
  display: flex;
  flex-direction: column;
  background-color: $xpo-grey--80;
  border: 1px solid $xpo-grey--300;

  &-header {
    display: inline-flex;
    margin-top:$xpo-contentSpacing * 0.5;
    margin-left: $xpo-contentSpacing * 0.5;
    margin-right: $xpo-contentSpacing;
    margin-bottom: 0;
  }

  &-container {
    padding: $xpo-contentSpacing * 0.5;
  }

  &-global-filter{
    padding: $xpo-contentSpacing * 0.5;
    margin: 0;
  }

  &-btn-group {
    padding: $xpo-contentSpacing * 0.5;
    display: inline-flex;
    gap: $xpo-contentSpacing;
    vertical-align: bottom;
  }

  mat-form-field {
    margin-bottom: 0px !important;
    padding: $xpo-contentSpacing * 0.5;
    max-width: 200px;
  }
}
