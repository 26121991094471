/* imports and utilities like animations and mixins should be defined here */
@import '@xpo-ltl/ngx-ltl-core/styles/variables';
@import './flexboxgrid.scss';
@import './search-form.scss';

$app-actionBar-height: 40px;

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $app-actionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$app-actionBar-height;
    }
  }
}

.full-width {
  width: 100%;
}

[mat-icon-button].mat-icon-button {
  height: auto;
}

.field-group {
  display: flex;
  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }
}

.field {
  display: flex;
  flex-direction: column;
  margin-right: $xpo-contentSpacing * 2;
  &__label {
    font-weight: bold;
  }
  &:last-child {
    margin-right: unset;
  }
}

.mat-drawer-container {
  background-color: #ffffff;
}


.input-max-length {
  &-warning {
    color: $xpo-yellow--350 !important
  }

  &-error {
    color: $xpo-red--150 !important
  }
}

.ag-cell {
  overflow: hidden;
}

// Color alternate rows white and off-white for visibility
.ag-row {
  &.ag-row-odd:not(.ag-row-selected):not(.ag-row-hover) {
    background-color: $xpo-grey--80;
  }
}

// Pinned row color distinction on grid
.ag-floating-bottom-full-width-container,
.ag-floating-top-full-width-container {
  background-color: $xpo-blueGrey--700;
  opacity: 0.2;
}

/* Fix for the Floating Filter pinned header color */
.ag-pinned-left-header {
  background-color: $xpo-grey--80;
}

// Fix for SelectionWithSelectAll header cell not displaying
.ag-header-select-all {
  display: flex !important;
}

.xpo-ngxGrid-customColumns {
  background-color: $xpo-grey--80;

  &:hover {
    background-color: $xpo-grey--80 !important;
  }
}

// AG Grid Details additional styling
.ag-details-grid {
  border: 1px solid $xpo-grey--300;
}

.ag-grid .ag-ltr .ag-cell {
  height: 100%;
}

.ag-grid .ag-ltr .ag-group-contracted, .ag-grid .ag-ltr .ag-group-expanded {
  margin-right: $xpo-contentSpacing * 0.5 !important;
}

.grid-cell {
  &-editable {
    background-color: $xpo-yellow--200;
  }

  &-align-right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
}

// Grid toolbar styling overrides
.xpo-grid-toolbar {
  border: 1px solid $xpo-grey--300 !important;
}

.xpo-grid-toolbar-container-settings-buttons-group .mat-icon {
  height: 20px !important;
  width: 20px !important;
}

xpo-grid-toolbar {

  button,
  mat-slide-toggle {
    align-items: center !important;
    background: transparent !important;
    border: 0 !important;
    border-radius: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 8px !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 100% !important;
    width: auto !important;
    padding: 0 12px !important;
    font-size: 100% !important;

    xpo-icon,
    xpo-busy-loader {
      display: block !important;
      width: 20px !important;
      height: 20px !important;

      &::before {
        width: 20px !important;
        height: 20px !important;
        font-size: 20px !important;
      }
    }
  }

  .mat-button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  //For some reason the actions-group nests all elements in a div and the settings does not, so we need the added div tag here
  xpo-grid-toolbar-actions-group div {
    >button~* {
          border-left: 1px solid $xpo-grey--300 !important;

    }
    >button:last-child {
        border-right: 1px solid $xpo-grey--300 !important;
      }
  }

  xpo-grid-toolbar-settings-group {
    >*~* {
      border-left: 1px solid $xpo-grey--300 !important;
    }

    >*:first-child {
      border-left: 1px solid $xpo-grey--300 !important;
    }
  }
  xpo-grid-toolbar-toggle-buttons-group {
    >*~* {
      border-left: 1px solid $xpo-grey--300 !important;
    }

    >*:first-child {
      border-left: 1px solid $xpo-grey--300 !important;
    }
  }
}


 xpo-grid-toolbar-actions-group div form {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
  margin-left: 8px;

  mat-form-field{
      margin: 0 !important;


    }
 }

.risk-icon {
  color: $xpo-red--200 !important;
}

.non-risk-icon {
  color: $xpo-green--450 !important;
}

// Add Flex to AG Grid
.xpo-AgGrid {
  flex: 1;
  height: 100% !important;
}

.xpo-AgGrid .ag-floating-top, .xpo-AgGrid .ag-floating-bottom {
  font-weight: bold;
}

// Start - AG Set Filter Height Overrides
.xpo-AgGrid .ag-set-filter-list,
.xpo-AgGrid .ag-filter-no-matches {
  height: 229px !important;
}

// AG Grid Details additional styling
.ag-details-grid {
  border: 1px solid $xpo-grey--300;
}


$breakpoints: (
  xs: 0px,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px
);

@mixin respond-to($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}
@mixin flex-layout-number($size: 100){
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  max-width: $size;
}

.layout {
  display: flex !important;
  &.row {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &.column {
    flex-direction: column;
  }

  &.row-xs {
    @include respond-to(xs) {
      flex-direction: row;
    }
  }

  &.column-xs {
    @include respond-to(xs) {
      flex-direction: column;
    }
  }

  &.row-sm {
    @include respond-to(sm) {
      flex-direction: row;
    }
  }

  &.column-sm {
    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  &.row-md {
    @include respond-to(md) {
      flex-direction: row;
    }
  }

  &.column-md {
    @include respond-to(md) {
      flex-direction: column;
    }
  }

  &-gap-10 {
    gap: 10px;
  }

  &-gap-20 {
    gap: 20px;
  }

  &-gap-30 {
    gap: 30px;
  }

  &-gap{
    gap: $xpo-contentSpacing;
  }

  &-row-inline {
    display: inline-flex;
    flex-direction: row;
  }

  &-none-align {
    display: flex;
    justify-content: unset;
    align-items: unset;
  }

  &-align-center-center {
    justify-content: center;
    align-items: center;
  }

  &-align-space-around-center {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &-align-space-between-center{
    display: flex;
    justify-content: space-between center;
  }

  &-space-around-strech {
    justify-content: space-around stretch;
  }

  &-center-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-start-align {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.x-show-gt-sm  {
  display: none !important;
}

@media (min-width: 600px) {
  .x-hide {
    display: none !important;
  }
  .x-show-gt-sm {
    display: block !important;
  }
}

.flex-100 {
  @include flex-layout-number(100%);
}

.flex-120-pixel {
  @include flex-layout-number(120px);
}

.flex-70 {
  @include flex-layout-number(70%);
}

.flex-50 {
  @include flex-layout-number(50%);
}

.flex-25 {
  @include flex-layout-number(25%);
}

.flex-20 {
  @include flex-layout-number(20%);
}

.flex-30 {
  @include flex-layout-number(30%);
}

.flex-10 {
  @include flex-layout-number(10%);
}

.flex-5 {
  @include flex-layout-number(5%);
}

.flex-15 {
  @include flex-layout-number(15%);
}

.flex-3 {
  @include flex-layout-number(3%);
}


